import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import * as APIHelper from "../../Helper/apiFetch";
import './style.css';
import { Images } from "../../Constants";

const ModalPopup = ({ onClose }) => {
    const [val, setVal] = useState('');
    const [disableButton, setDisableButton] = useState(true);
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState('');
    const [timer, setTimer] = useState(0); // Timer starts at 0
    const [buttonTitle, setButtonTitle] = useState("SEND LINK");
    const [color, setColor] = useState("#A6A7A8");
    const [attemptCount, setAttemptCount] = useState(0);

    useEffect(() => {
        let intervalId;
        if (timer > 0) {
            intervalId = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else if (timer === 0 && buttonTitle !== "SEND LINK") {
            setDisableButton(false);
            setButtonTitle("RESEND LINK");
        }
        return () => clearInterval(intervalId);
    }, [timer, buttonTitle]);

    const startTimer = () => {
        setTimer(20);
        setDisableButton(true);
        setButtonTitle("20s");
    };

    const handelGetLink = async () => {
        if (attemptCount >= 2) {
            setMessage("We’re unable to resend you an invite SMS, Please try again later.");
            setDisableButton(true);
            return;
        }
        
        setLoader(true);
        setDisableButton(true);
        try {
            const resData = await APIHelper.requestLink(val);
            setMessage(resData.message);
        } catch (error) {
            console.error("Error", error);
        }
        setLoader(false);
        setAttemptCount(attemptCount + 1);
        startTimer();
    };

    const handelPhoneNumber = (e) => {
        const enteredValue = e.target.value.replace(/\D/g, '');
        setVal(enteredValue);
        if (enteredValue.length === 10) {
            setDisableButton(false);
            setButtonTitle("SEND LINK");
        } else {
            setDisableButton(true);
        }
    };

    const formatTimer = (time) => {
        return time < 10 ? `0${time}` : time;
    };

    return (
        <div className="modalContainer">
            <div style={{ margin: "50px 160px" }}>
                <button className="moadalClosBbutton" onClick={onClose}>
                    <img src={Images.close} alt=" " className="modalCloseButtonImg" />
                </button>
                <div className="modalTitle">
                    Download GoSats App
                </div>
                <div className="modalSubtitle">
                    Please enter your phone number to get the link
                </div>
                <div style={{ display: "flex", justifyContent: 'center', width: '100%' }}>
                    <div className="inputFieldContainer">
                        <span
                            style={{ color: "#A6A7A8", fontSize: "16px", fontWeight: 500 }}>
                            +91
                        </span>
                        <span className="inputFieldDivider">
                        </span>
                        <input
                            pattern="[0-9]*"
                            className="inputField"
                            maxLength='10'
                            placeholder="Eg:9900112233"
                            onChange={handelPhoneNumber}
                            value={val}
                        />
                        <div>
                            <button
                                className="getLinkButton"
                                onClick={handelGetLink}
                                disabled={disableButton}
                                style={{
                                    backgroundColor: disableButton ? "#343739" : "#D1964A",
                                    color: disableButton ? "#A6A7A8" : "#FFFFFF"
                                }}
                            >
                                {
                                    loader ?
                                        <ClipLoader
                                            color={color}
                                            loading={loader}
                                            size={20}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        />
                                        :
                                        timer > 0 ? `Resend in ${formatTimer(timer)}s` : buttonTitle
                                }
                            </button>
                        </div>
                    </div>
                </div>
                {
                    message ?
                        <div className="modalTimerConatiner">
                            <div className="modalRespMessage">
                                {message}
                            </div>
                        </div>
                        :
                        null
                }
                <div className="orContainer">
                    <span className="orDivider"></span>
                    <div className="orTitle">OR</div>
                    <span className="orDivider"></span>
                </div>
                <div className="ModalQRConatiner">
                    <div className="ModalQRTitle">
                        Scan the QR
                    </div>
                    <div>
                        <img src={Images.qr} alt=" " className="modalQRImg" />
                    </div>
                </div>
                <div className="modalDownloadButtonsContainer">
                    <span>
                        <a href="https://play.google.com/store/apps/details?id=io.gosats"
                            target="_blank" rel="noreferrer">
                            <img src={Images.playstore} alt=" " className="modalPlaystore" />
                        </a>
                    </span>
                    <span>
                        <a href="https://apps.apple.com/in/app/gosats-bitcoin-rewards-app/id1536263998"
                            target="_blank" rel="noreferrer">
                            <img src={Images.appstore} alt=" " className="modalAppstore" />
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ModalPopup;
