import React, { useState, useEffect } from 'react';
import './style.css';
import AlredyReg from '../AlredyRegistered/alredyReg';
import { Images, Strings, Href } from '../../Constants';
import ReactLottie from 'react-lottie';
import animationData from '../../LottieFiles/heroWebAnim.json';

const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData, 
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

const LandingScreen = () => {
    /* const initializeLottieAnimations = () => {
        const lottieOptions = {
            container: document.getElementById('lottie-container'),
            animationData: LandingScreenLottie,
            loop: true,
            autoplay: true,
        };
        
        console.log(lottieOptions);

        // Render Lottie animation
        Lottie.loadAnimation(lottieOptions);
    }
    
    // Call initializeLottieAnimations on component mount
    useEffect(() => {
        initializeLottieAnimations();
    }, []); */

    const [newUser] = useState(false);
    // const handleShow = () => setNewUser(true);

    return (
        <div>
            <div className='container overflow-hidden'>
                {newUser && <AlredyReg />}
                <div className='sectionOne'>
                    <div className=''>
                        <div className=' d-flex flex-wrap col-sm-12 col-md-12 col-lg-12 col-xl-12 homeScreen-title-container' >
                            <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 homeScreen-title' >
                                <div className='head-title' >{Strings.LANDING_HEADER_TITLE}</div>
                                <p className='sub-title col-12 col-sm-12 col-md-12 col-lg-10 col-11 col-xl-8' style={{ fontWeight: '' }}>{Strings.ACCUMULATE_BITCOIN}</p>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-7 homeScreen-phone2' >
                                    {/* <img className='homeScreen-img2' data-object-fit='contain'
                                        src={Images.phone2}
                                        alt='GoSats' /> */}
                                    {/* <video width="100%" muted playsInline autoPlay loop={false} preload='metadata' style={{visibility: 'visible', position: 'initial'}}>
                                        <source src={src} type="video/mp4"/>
                                        Your browser does not support the video tag.
                                    </video> */}
                                    <ReactLottie 
                                    options={defaultOptions}
                                    height={'100%'}
                                    width={'100%'}/>
                                </div>
                                <div>
                                    <div className='downloadLinks'>
                                        <DownloadableButtons />
                                    </div>
                                    <div className='downloadTitle'>Trusted By <span className='downloadTitleLinear'> 5 Lakh+ Customers</span> </div>
                                    <div className='downloadTitleMobile'>Trusted By <br /><span className='downloadTitleLinearMobile'> 5 Lakh+ Customers</span> </div>
                                </div>
                            </div>
                            {/* <div className='homebg'></div> */}
                            <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 homeScreen-phone' >
                                {/* <video width="100%" autoPlay playsInline muted preload='metadata' loop={false} style={{visibility: 'visible', position: 'initial'}}>
                                    <source src={src} />
                                    Your browser does not support the video tag.
                                </video> */}
                                <ReactLottie 
                                    options={defaultOptions}
                                    height={'auto'}
                                    width={'auto'}/>
                                {/* <video autoPlay loop muted style={{width: "100%", height: '100%', border: 'none', textDecoration: 'none'}}>
                                        <source src={video} type="video/mp4"></source>
                                </video> */}
                            </div>
                        </div>
                        {/* <InputField/>*/}
                        {/* <DownloadableButtons />  */}
                    </div>
                </div>
            </div>
        </div>
    );
}

/* const InputField = () => {
    return (
        <div className='col-12  mt-4 col-md-6 d-flex  phone-num-container' >
            <div className='col-5 col-md-6 col-lg-4 col-xl-3 input-phone-number' >
                <span style={
                    { color: "#FFFFFF" }} > +91 </span>
                <input type='text'
                    // className='input'
                    pattern="[0-9]*"
                    value={val}
                    onChange={(e) => setVal((v) => (e.target.validity.valid ? e.target.value : v))
                    }
                    maxLength='10'
                    size='10'
                    className='phone-num-input' />
            </div>
            <div className='col-6 col-md-6  ' >
                <button className=' send-button' onClick={handleShow}>
                    Get Free Bitcoin
                </button>
            </div>
        </div>
    )

} */

const DownloadableButtons = () => {
    // return downloadsData.map(content => {
    return (
        /* <div className= "col-md-4 col-lg-4" >
            <a href={content.STORE_LINK}
                target="_blank" >
                <img src={content.IMAGE}
                    className={content.IMG_CSS_CLASS}
                    alt={content.NAME}
                    style={{ width: 126, hight: 40 }}
                />
            </a>
            <div className='col-12  start-rating' >
                <div className="number-rating me-2 " >{content.RATING}</div>
                <div className="mt-2"><StarRatings value={content.VALUE} /></div>
            </div>
        </div> */
        <div className="col-12 col-md-12 col-lg-12 col-xl-12 d-flex  downloadLinksContainer" >
            <div className=" col-md-6 col-lg-7 col-xl-6 button-appStore playstore" >
                <a href="https://gosats.onelink.me/O1LB/486vmbyi"
                    target="_blank" rel="noreferrer">
                    <img src={Images.playstore}
                        className="googlePlayLogo"
                        alt="Google play"
                        style={{ width: 126, hight: 40 }}
                    />
                </a>
                {/* <div className='start-rating mt-2' >
                    <div className="number-rating me-2" >4.8</div>
                    <img src={Images.appStar} className='star' alt='Ratings' />
                </div> */}
            </div>
            <div className=" col-md-6 col-lg-4 col-xl-6 button-appStore appstore" >
                <a href="https://gosats.onelink.me/O1LB/3n15ro4v"
                    target="_blank" rel="noreferrer">
                    <img src={Images.appstore}
                        className="appStoreLogo"
                        alt="App Store"
                        style={{ width: 126, hight: 40 }}
                    />
                </a>
                {/* <div className='col-12 mt-2 start-rating' >
                    <div className="number-rating me-2 " >4.7</div>
                    <img src={Images.appStar} className='star' alt='Ratings' />
                </div> */}
            </div>

        </div>
    )
    // })
}


export default LandingScreen;
// <img src={Images.appStar} className='star' />
/* <div className="col-12 col-md-6 col-lg-5 d-flex row downloadLinks  d-md-flex" >
<DownloadableButtons />
</div> */