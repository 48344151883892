import React from 'react';
import './abtHomeScreen.css';
import {Headder} from '../../components';
import { Strings } from '../../Constants';


const AboutLandingPage = () => {
    return (
        <div className='abtMainSection'>
            <div className='AbtScreen '>
                <div className='abtbg'></div>
                <div className='container AbtSectionOne'>
                    <div className='AbtTitleSection col-lg-12'>
                        <h2 className='AbtHeadderTitle col-12 col-lg-7'>{Strings.ABOUT_LANDINGPAGE_TITLE}<span className='web3'>Web3</span></h2>
                       {/*  <p className='AbtSubTitle col-12 col-lg-12'>{Strings.ABOUT_LANDINGPAGE_SUBTITLE}</p> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutLandingPage;