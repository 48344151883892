import React from "react";
const RedirectComponent = () => {
    // Redirect function
    React.useEffect(() => {
      window.location.href = 'https://gosats.page.link/2WUb';
    }, []);
  
    return null;
  };

export default RedirectComponent;