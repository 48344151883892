import React, { useState, useEffect } from "react";
import * as APIHelper from "../../Helper/apiFetch";
import OTPInput from "react-otp-input";
import Headder from "../Header";
import './style.css';

const DeleteAccount = () => {

    const [val, setVal] = useState('');
    const [disableButton, setDisableButton] = useState(true);
    const [message, setMessage] = useState(null);
    const [timer, setTimer] = useState(20);
    const [buttonTitle, setButtonTitle] = useState("Get OTP");
    const [otp, setOtp] = useState('');
    const [disableInput, setDisableInput] = useState(false);
    const [proceedBtnDisabled, setProceedBtnDisabled] = useState(true);
    const [hideProceedButton, setHideProceedButton] = useState(false);
    const [displayOTP, setDisplayOTP] = useState(false);

    const startTimer = () => {
        setTimer(20);
        const intervalId = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        // Clear the interval after 20 seconds
        setTimeout(() => {
            clearInterval(intervalId);
            setButtonTitle("Resend OTP");
        }, 20000);
    };

    const handelGetOTP = async () => {
        setDisableButton(true);
        setDisableInput(true);
        try {
            const resData = await APIHelper.getOTP(val);
            setDisplayOTP(true);
        } catch (error) {
            console.error("Error", error);
        }
    };

    const handleVerify = async () => {
        setProceedBtnDisabled(true);
        try {
            const resData = await APIHelper.verifyOTP(val, otp);
            setMessage(resData.message);
            setHideProceedButton(true);
        } catch (error) {
            console.error("Error", error);
        }
    };

    const handelPhoneNumber = (e) => {
        const enteredValue = e.target.value.replace(/\D/g, '');
        setVal(enteredValue);

        setDisableButton(enteredValue.length !== 10);
    }

    useEffect(() => {
        if (timer === 0) {
            setButtonTitle("RESEND LINK");
            setDisableButton(false);
        }
    }, [timer]);


    const handleChange = (value) => {
        setOtp(value);
        const otpRegex = new RegExp('^\\d{3}$');
        const validity = otpRegex.test(otp);

        if (validity) {
            setProceedBtnDisabled(false);
        }
    }

    return (
        <div className="container">
            <Headder />
            <div className="modalContainer">
                <div >
                    <div className="modalTitle">
                        Request Account Deletion
                    </div>
                    <div className="modalSubtitle">
                        Please enter your phone number linked with your GoSats account
                    </div>
                    <div style={{ display: "flex", justifyContent: 'left', width: '100%', alignItems: 'left' }}>
                        <div className="inputFieldContainer">
                            <span
                                style={{ color: "#A6A7A8", fontSize: "16px", fontWeight: 500 }}>
                                +91
                            </span>
                            <span className="inputFieldDivider">
                            </span>
                            <input
                                pattern="[0-9]*"
                                className="inputField"
                                disabled={disableInput}
                                maxLength='10'
                                placeholder="Eg:9900112233"
                                onChange={(e) => handelPhoneNumber(e)}
                                value={val}
                            />
                            <div>
                                <button
                                    className="getOTPButton"
                                    onClick={() => handelGetOTP()}
                                    disabled={disableButton}
                                    style={{
                                        backgroundColor: disableButton ? "#343739" : "#D1964A",
                                        color: disableButton ? "#A6A7A8" : "#FFFFFF"
                                    }}
                                >
                                    Get OTP
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            displayOTP ?
                                <div>
                                    <div style={{ color: '#FFFFFF', fontSize: '18px', fontWeight: 600, textAlign: 'left' }}>
                                        Please enter OTP to verify your phone number
                                    </div>
                                    <div className="otp-input">
                                        <OTPInput
                                            onChange={handleChange}
                                            isInputNum={true}
                                            numInputs={4}
                                            value={`${otp}`}
                                            inputStyle="inputOtp"
                                            containerStyle="inputContainer"
                                            renderInput={(inputProps, index) => {
                                                return <input {...inputProps} />;
                                            }}
                                        />
                                    </div>
                                    {
                                        message ?
                                            <div className="modalTimerConatiner">
                                                <div className="modalRespMessage">
                                                    {message}
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        hideProceedButton ?
                                            null
                                            :
                                            <button
                                                className="proceedButton"
                                                style={{
                                                    backgroundColor: proceedBtnDisabled ? "#343739" : "#D1964A",
                                                    color: proceedBtnDisabled ? "#A6A7A8" : "#FFFFFF"
                                                }}
                                                onClick={() => handleVerify()}
                                                disabled={proceedBtnDisabled}
                                            >
                                                Proceed
                                            </button>
                                    }
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteAccount;