import React from 'react';
import './goSatsTeamTitle.css';
import { Strings } from '../../Constants';

const GoSatsTeamTitle = () =>{
    return(
        <div className='container'>
            <div className='teamTitle'>
                <h1 className='teamHeadder'>{Strings.TEAM_SECTION_TITLE}</h1>
                <p className='teamSubTitle'>{Strings.TEAM_SECTION_SUBTITLE}</p>
            </div>
        </div>
    );
}

export default GoSatsTeamTitle;