import React from 'react';
import './style.css'
import Accordion from '../accordion';

const NavVoucher = () => {
    const accordionData = [{
        title: 'What are vouchers?',
        content: `Vouchers are like gift cards that can be used to make purchases with their corresponding brands. Not only do you get higher rates of rewards with vouchers, the sats are credited immediately as well, as opposed to direct shopping which takes up to 90 days for sats to be credited.  `
    },
    {
        title: 'How to redeem vouchers?',
        content: `Every brand has different ways to redeem vouchers. Kindly contact our support team through the app if you are finding it difficult to redeem your voucher. `
    },
    {
        title: 'Where can I view the terms and conditions of vouchers?',
        content: `The details section on the voucher brand's page contains all the terms and conditions of that specific voucher. 
          Can I receive a refund once I have paid for a voucher? - Question
          Vouchers are non-refundable.`
    }];

    return (
        <div className="accordion" > {
            accordionData.map(({ title, content }, index) => (
                <Accordion title={title} content={content} key={index}
            />
            ))
        }
        </div>
    );
}

export default NavVoucher;