//API Base URL
const API_ROOT = 'https://api.gosats.io/v1/auth';

const API_URLS = {
    GET_OTP: '/acc/delete/otp/request',
    VERIFY_OTP: '/acc/delete/otp/verify',
    REQUEST_LINJK: '/user/request/downloadLink'
}

const AUTH_TYPE = {
    NO_AUTH: 0
}

const commonApiCall = async (apiURL, reqMethod, requestObj) => {
    try {
        const headerData = { 'Content-type': 'application/json' };
        const bodyData = requestObj ? JSON.stringify(requestObj) : null;

        const response = await fetch(apiURL, {
            method: reqMethod,
            headers: headerData,
            body: bodyData,
        });

        if (response.status === 200) {
            return response.json();
        } else return response.json();

        // throw new Error('Other error');
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export async function getOTP(phoneNumber) {
    const apiUrl = `${API_ROOT}${API_URLS.GET_OTP}`;
    const requestObj = {
        phoneNumber: `+91${phoneNumber}`
    };
    return await commonApiCall(apiUrl, 'POST', requestObj);
}

export async function verifyOTP(phoneNumber, otp) {
    const apiUrl = `${API_ROOT}${API_URLS.VERIFY_OTP}`;
    const requestObj = {
        phoneNumber: `+91${phoneNumber}`,
        code: otp
    };
    return await commonApiCall(apiUrl, 'POST', requestObj);
}

export async function requestLink(phoneNumber) {
    console.log(phoneNumber);
    const apiUrl = `${API_ROOT}${API_URLS.REQUEST_LINJK}`;
    const requestObj = {
        phoneNumber: `+91${phoneNumber}`
    };
    return await commonApiCall(apiUrl, 'POST', requestObj);
}