const Href ={
    YCOMBINATOR: 'https://www.ycombinator.com/companies/gosats',
    FINANCIALEXPRESS: 'https://www.financialexpress.com/digital-currency/gosats-news-india-first-bitcoin-rewards-platform-roshan-aslam-interview/2495995/',
    YOURSTORY: 'https://yourstory.com/2021/11/tech50-startup-gosats-bitcoin-rewards-crypto-startup-swiggy-flipkart-amazon-myntra/amp',
    INC42: 'https://inc42.com/buzz/bitcoin-reward-app-gosats-bags-funding-to-make-consumers-earn-more-cashbacks/',
    ECONOMICTIMES: 'https://bfsi.economictimes.indiatimes.com/news/financial-services/indias-first-bitcoin-rewards-company-gosats-raises-seed-funding-of-700k/85032337',
    ACCEL: 'https://atoms.accel.com/blog/accel-founder-stories-005-meet-gosats',
    CNBCTV: 'https://www.cnbctv18.com/startup/funding-rundown-coralogix-raises-142m-goldman-sachs-asset-management-lead-77m-round-in-moengage--cuemath-raises-57m-13686012.htm',
    ENTREPRENEUR: 'https://www.entrepreneur.com/article/379312',
    FREEPRESSJOURNAL:'https://www.freepressjournal.in/business/gosats-black-friday-week-campaign-users-to-receive-100-extra-bitcoin-rewards',
    ONRAMP_LINK: 'https://blog.gosats.io/bitcoin/how-bitcoin-rewards-is-superior-to-points-miles-and-other-reward-loyalty-programs/',
    GOSATS_BLOG: 'https://blog.gosats.io',
    GOSATS_CAREERS: 'https://www.workatastartup.com/companies/gosats',
    GOSATS_ONELINK: 'https://gosats.onelink.me/O1LB/96fiijac',
    GOSATS_CHROME_EXTENCTION: 'https://chrome.google.com/webstore/detail/gosats/hgngfllcalefeagkohnhehfdallpglcm',
    GOSATS_LEGAL: 'https://gosats.io/terms.html',
    GOSATS_SELF_CARE_PORTAL: 'https://gosats.livquik.com/login',
    DOWNLOAD_BUTTON_APPSTORE: 'https://gosats.onelink.me/O1LB/3n15ro4v',
    DOWNLOAD_BUTTON_PLAYSTORE: 'https://gosats.onelink.me/O1LB/486vmbyi',
    DOWNLOAD_BUTTON_CHROME_EXTENCTION: 'https://chrome.google.com/webstore/detail/gosats/hgngfllcalefeagkohnhehfdallpglcm',
    GOSATS_FACEBOOK:'https://www.facebook.com/gosatsapp',
    GOSATS_LINKEDIN: 'https://www.linkedin.com/company/gosats',
    GOSATS_TWITTER: 'https://www.twitter.com/gosatsapp',
    GOSATS_INSTAGRAM: 'https://www.instagram.com/gosatsapp',
    GOSATS_MAILTO: 'mailto:hello@gosats.io?Subject=Hello%20Team',
    GOSATS_TELEGRAM: 'https://t.me/gosatsapp',
    GOSATS_KOO: 'https://www.kooapp.com/profile/gosatsapp',
    GOSATS_WHATSAPP: 'https://chat.whatsapp.com/GQud8Y7HlN0An5LCzq1Itu',
    
}

export default Href;