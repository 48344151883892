import React from 'react';
import './style.css'
import Accordion from '../accordion';

const NavRefferal = () => {
  const accordionData = [
    {
      title: 'How does the referral system work?',
      content: (
        <>
          With our updated referral system, you can earn upto Rs750 worth of Sats for each friend you refer to the GoSats Card program. Invite your friends by sharing with them your unique referral code.
          <br />
          This is how the referral system works:
          <br />
          - Earn Sats worth Rs 250 (on the Elite Card) or Rs 100 (Intro Card) when your friend purchases the GoSats Card
          <br />
          - Earn 1% Sats back for Elite Card and 0.5% Sats back for Intro Card on their purchases for the first 3 months, up to a total of ₹500!
        </>
      )
    },
    {
      title: `Why haven't I received my referral rewards?`,
      content: (
        <>
          You are only able to earn Referral rewards for users who signed up for the GoSats App using your personalised referral link. If someone signed up for the App without using your link, you will not be eligible to receive any referral rewards.
          <br />
          Please also note that no referral rewards apply if the card is purchased during a special campaign (eg:- Independence Day campaign, Times Prime offer etc.)
          <br />
          Please contact our in-app support if you think you are eligible for rewards but haven’t been receiving them.
        </>
      )
    }
  ];
  return (
    <div className="accordion">
      {accordionData.map(({ title, content }, index) => (
        <Accordion title={title} content={content} key={index} />
      ))}
    </div>
  );
}

export default NavRefferal;