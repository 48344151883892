import './style.css';
import Images from '../../Constants/Images';

const initialData = require('./urlEncodeContent.json');

export default function LandingScreen() {

    const firstCard = initialData?.detaails;

    return (
        <div className='recapContainer'>
            <div className='headerSection'>
                <div>
                    <img src={Images.Logo} className='logoContainer' />
                </div>
                <div>
                    <img src={Images.TitleImg} className='titleImg' />
                </div>
                <div className='bodyTitle'>
                    What a year this has been for Bitcoin!
                </div>
                <div className='headderSubtitle'>
                    Coming off the euphoric highs off the previous year, we saw the price of Bitcoin crashing to 2-year lows. However, amidst the meltdown in the crypto market, Bitcoin adoption has only grown in leaps and bounds.
                </div>
            </div>
            <div className='bodyContainer'>
                <div className='bodySubtitle'>
                    We’ve kept giving out Bitcoin rewards throughout the year and as we’ve kept growing our family of stackers, we hope we’ve brought you one step closer to financial freedom.
                </div>

                <div style={{ position: 'relative', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'center' }}>
                    <div>
                        <img src={Images.atmCard} className='atmCard' />
                    </div>
                    <div className='bodySubtitle'>
                        This year, we released the GoSats Card and made it possible for Bitcoiners in the country to earn Bitcoin on all their spends.
                    </div>
                    <div className='rewardsectionTitle'>
                        Here’s A Recap Of Our Wonderful Year.
                    </div>
                    <div>
                        <img src={Images.RewardImg} className='ShoppingTrends' />
                    </div>
                    <div className='rewardsectionTitleTwo'>
                        What Could Be More Rewarding Than Earning Bitcoin Rewards?
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className='cardContainerHolder'>
                        {
                            firstCard.map((data, index) => {
                                return (
                                    <div className='cardContainer' style={{ backgroundImage: data.backgroundColor }}>
                                        <div className='cardTitle'>
                                            {data.title}
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img src={data.satsCoinImgOne} className='satsCoin' style={{ marginTop: '20px' }} />
                                            <div className='cardSubtitle'>
                                                {data.subtitleOne}
                                            </div>
                                            <img src={data.satsCoinImgTwo} className='satsCoin' style={{ paddingBottom: '20px' }} />
                                        </div>
                                        <div className='cardSubtiltleTwo'>
                                            {data.subtitleTwo}
                                        </div>
                                        <div>
                                            {
                                                data.footerTitle === undefined ?
                                                    null
                                                    :
                                                    <div className='cardFooter' style={{ padding: '20px' }}>
                                                        <img src={data.footerImg} className='satsCoin' />
                                                        <div className='cardFooterTitle'>
                                                            {data.footerTitle}
                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='spinwheelimgContainer'>
                    <img src={Images.SpinWheel} className='SpinWheelImg' />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-70px' }}>
                    <div>
                        <div className='cardContainer' style={{ backgroundImage: 'linear-gradient(90deg, #C2D1FF 0%, #A4B1F4 100%)' }}>
                            <div className='cardTitle'>
                                You Spun The Wheel Nearly
                            </div>
                            <div className='cardSubtitle'>
                                25 LAKH
                            </div>
                            <div className='cardSubtiltleTwo'>
                                Times In 2022 <br /> Your Head Must Be Spinning With All Of Those Spins!
                            </div>
                        </div>
                        <div className='cardContainer' style={{ backgroundImage: 'linear-gradient(180deg, #FFEDBE 0%, #EFCC97 100%)' }}>
                            <div className='cardTitle'>
                                You Preferred Spins Over Flat Rewards On Your Card
                            </div>
                            <div className='spinRangeBarContainer'>
                                <div className='spinRange'>
                                    Spin 65%
                                </div>
                                <div className='flatRange'>
                                    Flat 35%
                                </div>
                            </div>
                            <div className='cardSubtiltleTwo'>
                                1BTC Reward With The Spin Wheel, Yet To Be Claimed
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <img src={Images.ShoppingTrends} className='ShoppingTrends' />
                </div>
                <div className='collectivelyContainer'>
                    <div className='cardContainer' style={{ backgroundImage: 'linear-gradient(90deg, #FFCFC9 0%, #EEA5A5 100%)' }}>
                        <div className='cardTitle' >
                            Collectively You Spent The Most On
                        </div>
                        <div className='iconsContainer'>
                            <div>
                                <img src={Images.Pizza} className='cardImages' />
                                <div className='iconNames'>Food</div>
                            </div>
                            <div>
                                <img src={Images.foodBasket} className='cardImages' />
                                <div className='iconNames'>Groceries</div>
                            </div>
                            <div>
                                <img src={Images.fashion} className='cardImages' />
                                <div className='iconNames'>Fashion</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className='swiggyCardContainer' style={{ backgroundImage: 'linear-gradient(90deg, #F4EEE2 0%, #F6D5AC 100%)' }}>
                        <div>
                            <img src={Images.swiggyBg} className='swiggyBg' />
                            <div className='cardTitle' style={{ margin: '20px' }}>
                                Our most popular voucher brand was
                            </div>
                            <div>
                                <img src={Images.swiggyLogo} className='swiggyLogo' />
                            </div>
                            <div className='cardSubtiltleTwo' style={{ margin: '20px' }}>
                                Clearly, You Love Food - Both As Fuel For The Soul, And To Stack Bitcoin Rewards!
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className='BitFriday' style={{ backgroundImage: 'linear-gradient(90deg, #C2D1FF 0%, #A4B1F4 100%)' }}>
                        <div className='cardTitle'>
                            You Spent The Most During
                        </div>
                        <img src={Images.BitFriday} className='BitFridayImg' />
                        <div className='cardSubtiltleTwo'>
                            Weekend <br />
                            Clearly, You Loved 2x Bitcoin Rewards On Your GoSats Card!
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className='lastCard' style={{ backgroundImage: ' linear-gradient(90deg, #FFCFC9 0%, #EEA5A5 100%)' }}>
                        <div className='cardTitle'>
                            And we’re not stopping there…
                        </div>
                        <div className='cardSubtiltleTwo'>
                            We Have Lots Of Exciting Plans For 2023.
                        </div>
                    </div>
                </div>
            </div>
            <div className='yearRecapfooterSection'>
                <div>
                    <img src={Images.FooterImg} className='footerTitle' />
                </div>
            </div>
        </div>
    )
}