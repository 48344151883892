import React from 'react';
import './style.css'
import Accordion from '../accordion';

const NavCard = () => {
  const accordionData = [
    {
      title: 'What is the GoSats card and how does it work?',
      content: (
        <>
        The GoSats Rewards Card is a prepaid card that enables you to earn Sats seamlessly on every transaction – whether that be for your shopping or your monthly payments.  Since it is a prepaid card, it will need to be topped up via the app before it can be used. 
        <br />
        The card can be used both for online as well as offline transactions across any PoS device in India.
        </>
      )
    },
    {
      title: 'How can the card be topped up?',
      content: `The card can be topped up easily through the GoSats app. We support all types of payments to load the card.`
    },
    {
      title: 'What are the load and spend limits on the GoSats Card?',
      content: (
        <>
          The load and spend limits on the GoSats Card once an user completes VKYC are as follows:
          <br />
          Daily – Rs2 lakhs<br />
          Monthly – Rs 10 lakhs<br />
          Yearly – Rs30 lakhs<br />
          At a time, an amount of Rs 2 lakhs can be loaded and spent with the card.
          <br />
          If a user has only completed min KYC, the load and spend limits on the card will be Rs10,000 per month.
        </>
      )
    },
    {
      title: 'What are the fees and benefits of the card?',
      content: (
        <>
          We currently have 2 types of Rewards Cards: Intro and Elite.
          <br />
          The Elite Card comes with a one-time fee of Rs.999, 1.5% cashback on all spends (above Rs 200).
          <br />
          The Intro Card comes with a one-time fee of Rs.399, 0.5% cashback on all spends (above Rs 200).
        </>
      )
    }
  ];
  return (
    <div className="accordion">
      {accordionData.map(({ title, content }, index) => (
        <Accordion title={title} content={content} key={index} />
      ))}
    </div>
  );
}

export default NavCard;