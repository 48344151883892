import React from 'react';
import './style.css';

const Tweet = (props) => {
    var { twitterImg, twitterImgMob, link, imgmarginleft } = props.coustImgData
    return (
        <div className='mt-4 twitte-container'>
            <a href={link}>
                <img src={twitterImg} alt='Customer review' className='twitteImg' />
                <img src={twitterImgMob} alt='Customer review' style={{marginLeft:imgmarginleft}} className='twitteImgMob'/>
            </a>
        </div>
    );
}
export default Tweet;

