import React from 'react';
import './goSatsTeam.css';
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";

/*
@name GoSatsTeam
@param empDetail contains  empImage, empName, empDesg, linkedin, twitter
*/
const GoSatsTeam = (props) => {
    var { empImage, empName, empDesg, linkedin, twitter } = props.empDetail
    return (
        <div className='col-md-7 col-lg-6 col-xl-4 emp  d-flex flex-column align-item-center teamContainer'>
            <div className='teamImgContainer '>
                <img src={empImage} className='teamImg' alt='Profile' />
                {/*  <div className='middle'>
                    <a href={linkedin}>
                        <FaLinkedinIn color='#A6A7A8' size='60px' />
                    </a>
                </div> */}
            </div>
            <div className='empDetails'>
                <h4 className='empName '>{empName}</h4>
                <p className='empWrk'>{empDesg}</p>
                <div className='d-flex empProfile'>
                    <div className='icon'>
                        <a href={linkedin} className='team-footer-Social-media' target="_blank" rel="noreferrer">
                            <FaLinkedinIn color='#A6A7A8' className='team-socialmedia-icon' />
                        </a>
                    </div>
                    {
                        twitter ?
                            <div className='icon'>
                                <a href={twitter} className='team-footer-Social-media' target="_blank" rel="noreferrer">
                                    <FaTwitter color='#A6A7A8' className='team-socialmedia-icon' />
                                </a>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
        </div>
    );
}


export default GoSatsTeam;