import React from 'react';
import './style.css';
import { Images, Strings } from '../../Constants';

const rewardsDetails = [

    {
        IMAGE: Images.SpendEarn,
        TITLE: Strings.YOURS_FOREVER,
        SUBTITle: Strings.YOURS_FOREVER_SUBTITLE,
        CLASSNAME:  'forever-title-container'
    },
    {
        IMAGE: Images.RedeemIcon,
        TITLE: Strings.SPEND_EARN_TITLE,
        SUBTITle: Strings.SPEND_EARN_SUBTITLE,
        CLASSNAME: 'redeem-title-container'
    }
]

const Rewards = () => {
    return (
        <div className='container rewards'>
            <div className='col-12 text-center d-flex justify-content-center rewards-section  '>
                <div className='rewards-section-one '></div>
                    <div className='rewards-title'>
                        <div>{Strings.REWARDS_TITLE}</div>
                    </div>
            </div>
            <div className='d-flex col-lg-12 col-xl-12 reward-holder1'>
                <RewardsSectionOne />
                <div className='row reward-sectionTwo'>
                <RewardsSectionTwo data={rewardsDetails}/>
                </div>
            </div>
        </div>
    );
}

// const RewardsSectionOne = () => {
//     return (
//         <div className='col-12 col-md-12 col-lg-6 col-xl-6 d-flex  align-items-center flex-wrap   reward-sectionOne'>
//             <img className='col-7 col-md-4 col-lg-12 col-xl-8  spendEarnImg' src={Images.SpendEarn} alt='SpendAndEarn' />
//             <div className='col-12 col-md-6 col-lg-11 col-xl-11 spend-earn-title-section'>
//                 <div className='spend-earn-title ' >
//                     <h2 className='spend-earn-title'>{Strings.SPEND_EARN_TITLE}</h2>
//                 </div>
//                 <div className='col-11 col-lg-7 spend-earn-subtitle ' >
//                     <p>{Strings.SPEND_EARN_SUBTITLE}</p>
//                 </div>
//             </div>
//         </div>
//     )
// }

const RewardsSectionOne = () => {
    return (
        <div className='col-12 col-md-12  reward-sectionOne'>
            <img className='col-7 col-md-4 col-lg-12 col-xl-8  spendEarnImg' src={Images.LifetimeValidity} alt='SpendAndEarn' />
            <div className='col-12 col-md-6 col-lg-11 col-xl-11 spend-earn-title-section'>
                <div className='spend-earn-title ' >
                    <h2 className='spend-earn-title'>{Strings.GROWTH_TITLE}</h2>
                </div>
                <div className='col-12 col-md-12 col-lg-9 col-xl-12 spend-earn-subtitle' >
                    <div>{Strings.GROWTH_TITLE_SUBTITLE}</div>
                </div>
            </div>
        </div>
    )
}

const RewardsSectionTwo = ({ data }) => {
    return data.map((content, index) => (
        <div className={content.CLASSNAME} key={index}>
            <img className='lifetimeValidity col-6 col-md-4 col-lg-4' src={content.IMAGE} alt='Lifetime Validity' />
            <div className='col-12 col-md-6 col-lg-7 lifetime-title '>
                <h2 className='forever-title'>{content.TITLE}</h2>
                <p className='lifetime-subtitle col-lg-12 col-xl-12'>{content.SUBTITle}</p>
            </div>
        </div>
    ))
}



export default Rewards;