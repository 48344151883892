import appStar from '../images/app-star.png';
import webStar from '../images/web-star.png';
import phone from '../images/Phone1.png';
import appstore from '../images/app-store-button.png';
import playstore from '../images/google-play-button.png';
import chrome from '../images/browser-button.png';
import yourStory from '../images/PR/yourStory.png';
import inc42 from '../images/PR/Inc42.png';
import IBS from '../images/PR/IBS.png';
import FE from '../images/PR/financialExpress.png';
import YComb from '../images/PR/YComb.png';
import accel from '../images/PR/accel.png';
import CNBC from '../images/PR/CNBC.png';
import ET from '../images/PR/ET.png';
import entrepreneur from '../images/PR/entrepreneur.png';
import FP from '../images/PR/FP.png';
import phones from '../images/topBrandsPhone.png';
import SpendEarn from '../images/SpendEarnIcon.png';
import RedeemIcon from '../images/RedeemIcon.png';
import LifetimeValidity from '../images/LifetimeValidity Icon.png';
import debitCard from '../images/card.png';
import Hblue from '../images/Hblue.png';
import Hred from '../images/Hred.png';
import team from '../images/team/team.png';
import team2 from '../images/team/team2.png';
import VisionDots from '../images/valuesDots.png';
import koo from '../images/koo.svg';
import gosats from '../images/logo.png';
import comImg from '../images/comImg.png';
import logo from '../images/logo.png';
import qr from '../images/gosats_qr_code.png';
import buttonQR from '../images/QR.png';
import mobileView from '../images/mobileView.png';
import cardLandingImg from '../images/cardLandingImg.png';
import reward from '../images/rewards.png';
import spin from '../images/spin.png';
import secure from '../images/secure.png';
import introCard from '../images/IntroCard.png';
import eliteCard from '../images/EliteCard.png';
import vectorRight from '../images/vectorRight.png';
import vectorWrong from '../images/vectorWrong.png';

import Logo from '../images/YearRecapImg/Logo.png';
import TitleImg from '../images/YearRecapImg/title.png';
import Bgcircle from '../images/YearRecapImg/Group.png';
import RewardImg from '../images/YearRecapImg/RewardImg.png';
import SpinWheel from '../images/YearRecapImg/SpinWheel.png';
import ShoppingTrends from '../images/YearRecapImg/ShoppingTrends.png';
import Pizza from '../images/YearRecapImg/Pizza.png';
import fashion from '../images/YearRecapImg/fashion.png';
import foodBasket from '../images/YearRecapImg/food-basket.png';
import swiggyBg from '../images/YearRecapImg/swiggyBg.png';
import swiggyLogo from '../images/YearRecapImg/swiggyLogo.png';
import BitFriday from '../images/YearRecapImg/BitFriday.png';
import FooterImg from '../images/YearRecapImg/footerImage.png';
import satsCoin from '../images/YearRecapImg/satsCoin.png';
import  atmCard from '../images/YearRecapImg/atmCard.png';
import prMediaBg from '../images/pr-media-bg.png';
import phone2 from '../images/Phone2.png';
import instantImg from '../images/instantImg.png';
import timer from '../images/timer.png';
import close from '../images/close.png';
import landingVectorBg from '../images/landingVectorBg.png';
import satsPay from '../images/satsPayImg.png';
import satsPaySubImgOne from '../images/satsPaySubImgOne.png';
import satsPaySubImgTwo from '../images/satsPaySubImgTwo.png';
import satsPayFAQImg from '../images/satsPayFAQImg.png';


const Images ={
    appStar, 
    webStar, 
    phone, 
    appstore, 
    playstore, 
    chrome,
    yourStory,
    inc42, 
    IBS, 
    FE, 
    YComb, 
    accel, 
    CNBC, 
    ET, 
    entrepreneur, 
    FP,
    phones,
    SpendEarn,
    RedeemIcon,
    LifetimeValidity,
    debitCard,
    Hblue,
    Hred,
    team,
    team2,
    VisionDots,
    koo,
    gosats,
    comImg,
    logo,
    qr,
    buttonQR,
    mobileView,
    Logo,
    TitleImg,
    Bgcircle,
    RewardImg,
    SpinWheel,
    ShoppingTrends,
    Pizza,
    fashion,
    foodBasket,
    swiggyBg,
    swiggyLogo,
    BitFriday,
    FooterImg,
    satsCoin,
    atmCard,
    cardLandingImg,
    reward,
    spin,
    secure,
    eliteCard,
    introCard,
    vectorRight,
    vectorWrong,
    prMediaBg, 
    phone2,
    instantImg,
    timer, 
    close,
    landingVectorBg,
    satsPay,
    satsPaySubImgOne,
    satsPaySubImgTwo,
    satsPayFAQImg
}

export default Images;
