import React, { useState, useEffect } from "react";
import './style.css';
import { Images, Strings, Href } from '../../Constants';
import { FaqNavbar, Rewards } from '../../components';
import { Config } from '../../Configs';
import ModalPopup from "../ModalPopup";
import Popup from "reactjs-popup";
import ReactLottie from 'react-lottie';
import animationData from '../../LottieFiles/card_hero Web_Anim.json';
import checkCorrect from '../../images/checkCorrect.png';
import checkWrong from '../../images/checkWrong.png';
import recommendedIcon from '../../images/recommended.png';

const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData, 
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

const rewardCardDetails = [
    {
        img: Images.reward,
        cardTitle: Strings.CARD_FEATURE_SUBTITLE_ONE,
        cardSubTitle: Strings.CARD_FEATURE_SUBTITLEONE
    },
    {
        img: Images.spin,
        cardTitle: Strings.CARD_FEATURE_SUBTITLE_TWO,
        cardSubTitle: Strings.CARD_FEATURE_SUBTITLETWO
    },
    {
        img: Images.secure,
        cardTitle: Strings.CARD_FEATURE_SUBTITLE_THREE,
        cardSubTitle: Strings.CARD_FEATURE_SUBTITLETHREE
    }
]

const featuresTitle = [
    {
        recommended: false,
        cardImg: Images.introCard,
        cardTitle: "Features",
        pricePer: "/ ONE TIME CHARGE",
        border: 'none',
        bgColor: 'none',
        color: '#FFFFFF',
        textAlign: 'left',
        features: [
            {
                featureText: "Fixed rewards percentage"
            },
             {
                 featureText: "Boosted rewards on voucher purchase"
             },
            {
                featureText: "Boosted rewards on Myntra"
            },
            {
                featureText: "Physical card"
            },
            {
                featureText: "SatsPay"
            },
            {
                featureText: "Rewards redemption to buy voucher"
            },
            {
                featureText: "PhonePe vouchers"
            },
            {
                featureText: "One Time fee"
            }
        ]
    },
]

const cardDetails = [
    {
        recommended: false,
        cardImg: Images.introCard,
        cardTitle: "Intro Card",
        price: "Free",
        pricePer: "/ ONE TIME CHARGE",
        border: 'none',
        borderRadus: true,
        bgColor: '#141516',
        features: [
            {
                featureText: "0.5%",
                lineHight: '75px'
            },
             {
                 featureText: "50%",
                 lineHight: '70px'
             },
            {
                featureText: "4.5%",
                lineHight: '60px'
            },
            {
                featureText: "₹399",
                lineHight: '56px'
            },
            {
                imgUrl: checkWrong,
                lineHight: '44px'
            },
            {
                imgUrl: checkWrong,
                lineHight: '44px'
            },
            {
                imgUrl: checkWrong,
                lineHight: '47px'
            },
            {
                featureText: "FREE",
                lineHight: '0px'
            }
        ]
    },
    {
        recommended: true,
        cardImg: Images.eliteCard,
        cardTitle: "Elite Card",
        price: "₹999",
        gst: "+GST",
        pricePer: "/ ONE TIME CHARGE",
        border: '1px solid #C9A67A',
        borderRadus: true,
        marginRight: '60px',
        bgColor: 'linear-gradient(180deg, #1A1917 0%, #2D2212 100%)',
        lineHight: '75px',
        color: '#F5DCBC',
        features: [
            {
                featureText: "1.5%",
                lineHight: '75px'
            },
             {
                 featureText: "100%",
                 lineHight: '70px'
             },
            {
                featureText: "6%",
                lineHight: '60px'
            },
            {
                featureText: "Free",
                lineHight: '56px'
            },
            {
                imgUrl: checkCorrect,
                lineHight: '44px'
            },
            {
                imgUrl: checkCorrect,
                lineHight: '44px'
            },
            {
                imgUrl: checkCorrect,
                lineHight: '47px'
            },
            {
                featureText: "₹999",
                lineHight: '0px'
            }
        ]
    }
]

const CardScreen = () => {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <CardLandingScreen />
            <Rewards />
            {/* <GosatsCard /> */}
            <ChooseCard />
            <FAQ />
        </div>
    )
}

const CardLandingScreen = () => {


    return (
        <div className='cardScreenMain'>
            <div className='cardScreen '>
                <div className='container cardScreenSectionOne'>
                    <div className='col-md-12 col-lg-12 col-xl-12 card-homeScreen-title-container' >
                        <div className="col-sm-6 col-md-12 col-lg-6 col-xl-6">
                            <div className='homeScreen-title' >
                                <div className='head-title-card col-12 col-md-12 col-lg-12 col-xl-12'>Get India's First Sats Rewards Card</div>
                                <div className='cardScreen-sub-title col-12 col-md-12 col-lg-12 col-11 col-xl-11' >{Strings.CARD_REVOLUTION}</div>
                            </div>
                            <div className='col-12 col-md-12 cardScreen-card-mob' >
                                {/* <img className='card-homeScreen-img'
                                    src={Images.cardLandingImg}
                                    alt='GoSats' /> */}
                                {/* <video className='card-homeScreen-img' poster="https://assets.gosats.io/videos/card_hero_web_anim.mp4" loop={false} muted playsinline preload='metadata' style={{visibility: 'visible', position: 'initial'}}>
                                    <source src={src} />
                                    Your browser does not support the video tag.
                                </video> */}
                                <ReactLottie
                                    options={defaultOptions}
                                    height={'100%'}
                                    width={'100%'} />
                            </div>
                            <div>
                                <div className='col-md-12 col-lg-12 downloadLinks'>
                                    <DownloadableButtons />
                                </div>
                                <div className='downloadTitle'>Trusted by <span className='downloadTitleLinear'> 5 Lakh+ Customers</span> </div>
                                <div className='downloadTitleMobile'>Trusted by <br /><span className='downloadTitleLinearMobile'> 5 Lakh+ Customers</span> </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 col-xl-5 cardScreen-card' style={{marginTop: '-100px'}}>
                            <ReactLottie
                                options={defaultOptions}
                                height={'auto'}
                                width={'auto'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const DownloadableButtons = () => {
    return (

        <div className="col-12 col-md-12 col-lg-12 col-xl-12 d-flex  downloadLinksContainer" >
            <div className="col-md-4 col-lg-7 col-xl-6 button-appStore playstore" >
                <a href="https://gosats.onelink.me/O1LB/486vmbyi"
                    target="_blank" rel="noreferrer">
                    <img src={Images.playstore}
                        className="googlePlayLogo"
                        alt="Google play"
                        style={{ width: 126, hight: 40 }}
                    />
                </a>

            </div>
            <div className="col-md-4 col-lg-4 col-xl-6 button-appStore appstore" >
                <a href="https://gosats.onelink.me/O1LB/3n15ro4v"
                    target="_blank" rel="noreferrer">
                    <img src={Images.appstore}
                        className="appStoreLogo"
                        alt="App Store"
                        style={{ width: 126, hight: 40 }}
                    />
                </a>
            </div>
        </div>
    )
}

const GosatsCard = () => {
    return (
        <div className="container sectionTwoContainer">
            <div className="sectionTwoTitle">{Strings.CARD_FEATURE_TITLE}</div>
            <div className='cardHomebg'></div>
            <div className="sectionTwoCards">
                {
                    rewardCardDetails.map((data, index) => (
                        <div className="cards" key={index}>
                            <img className="cardRewardImg" src={data.img} alt="" />
                            <div className="cardHeader">{data.cardTitle}</div>
                            <div className="gosatsCardSubTitle">{data.cardSubTitle}</div>
                        </div>
                    ))

                }
            </div>
        </div>
    )
}

const ChooseCard = () => {

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handlePopup = () => {
        setIsPopupOpen(true);
    };

    useEffect(() => {
        if (isPopupOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [isPopupOpen]);

    return (
        <div className="container chooseCardContainer col-12 col-md-12 col-lg-12 col-xl-12">
            <div className="sectionTwoTitle">{Strings.CHOOSE_CARD_TITLE}</div>
            {/* <div className="chooseCard-sub-title">{Strings.CHOOSE_CARD_SUBTITLE}</div> */}
            <div className="col-12 col-md-10 col-lg-10 col-xl-8" style={{display:'inline-flex', justifyContent: 'center'}}>
                <div className="col-6 col-md-6 col-lg-6 col-xl-6">
                    {
                        featuresTitle.map((data, index) => (
                            <div className="chooseCardOneFeatureTitle" style={{ border: data.border, background: data.bgColor, paddingTop: '30px' }} key={index}>
                                <div className="chooseCardTitleOne" style={{ textAlign: 'left'}}>{data.cardTitle}</div>
                                <div className="cardDivider"></div>
                                {data.features.map((feature, index) => (
                                    <div className="checkPointsTitle" key={index}>
                                        <div className="checkTitle" style={{ color: data.color ? data.color : '#A6A7A8', textAlign: data.textAlign ? data.textAlign : 'center' }}>{feature.featureText}</div>
                                    </div>
                                ))}
                            </div>
                        ))
                    }
                </div>
                <div className="chooseCardOneContainer col-6 col-md-6 col-lg-6 col-xl-6">
                    {cardDetails.map((data, index) => (
                        <div className="chooseCardOne" style={{ border: data.border, background: data.bgColor, borderRadius: data.borderRadus ? '20px 20px' : '0px 0px' }} key={index}>
                            <div>
                                {data.recommended ? <div className="recommendedCard">Recommended</div> : ""}
                                {data.recommended ? <div className="recommendedIcon"><img className="recommendedImg" src={recommendedIcon}/></div> : ""}
                            </div>
                            <div className="chooseCardTitle">{data.cardTitle}</div>
                            <div className="cardPriceContainer">
                                <div className="cardPrice">{data.price ? data.price : " "}</div>
                                {data.gst ? <div className="gstTitle">{data.gst ? data.gst : null}</div> : null}
                                {/* <div classNa.checkTitleme="perTitle">{data.pricePer}</div> */}
                            </div>
                            <div className="cardDivider"></div>
                            {data.features.map((feature, index) => (
                                <div className="checkPointsTwo" key={index}>
                                    <div className="checkTitle" style={{ color: data.color ? data.color : '#A6A7A8',textAlign: data.textAlign ? data.textAlign : 'center', marginBottom: feature.lineHight}}>
                                        {feature.featureText ? 
                                            feature.featureText : feature.imgUrl ? <img className="checkImg" src={feature.imgUrl} />  : feature.featureText}</div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
            <div className="cardDownloadContainer">
                <div className="cardDownloadTitle">{Strings.CHOOSE_CARD_DOWNLOAD_TITLE}</div>
                <div>
                    <button
                        className="cardDownloadButtonTitle"
                        onClick={() => handlePopup()}
                    >
                        Get GoSats Card
                    </button>
                    <button
                        className="cardDownloadButtonTitleMob"
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href = Href.GOSATS_ONELINK;
                        }}
                    >
                        Book Now
                    </button>
                </div>
            </div>
            </div>
            <Popup
                open={isPopupOpen}
                closeOnDocumentClick
                onClose={() => setIsPopupOpen(false)}
            >
                {close => (
                    <ModalPopup onClose={() => { close(); setIsPopupOpen(false); }} />
                )}
            </Popup>
        </div>
    )
}

const FAQ = () => {
    return (
        <div className='faq-container'>

            <div className='container'>
                <div className='card-faq-subsection' >
                    <div className='col-12 faq-container'>
                        <div className='faq-title'>
                            <h2 className='faq-title'>{Strings.FAQ_TITLE}</h2>
                        </div>
                        <div className='col-11 faq-subtitle col-md-12'>
                            <p>{Strings.FAQ_SUBTITLE} Please write to us at  <a href={Config.MAIL_TO} className='goto-mail'>support@gosats.io</a></p>
                        </div>
                    </div>
                    <div className='col-12  faq-navbar'>
                        <FaqNavbar />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardScreen;