import React from 'react';
import './style.css';
import { Headder, DebitCard } from '../../components';
import { Strings } from '../../Constants';

const aboutCompany = () => {
    return (
        <div className='abtCompanyContainer'>
            <div className='container' style={{position: 'relative'}}>
                <Headder />
                <div className='aboutCompnyTitleContainer'>
                    <h4 className='aboutCompnyTitle col-11'>{Strings.ABOUT_COMPANY_TITLE}</h4>
                    <p className='aboutCompnySubtitle'>{Strings.ABOUT_COMPANY_SUBTITLE_ONE}</p>
                    <p className='aboutCompnySubtitle'>{Strings.ABOUT_COMPANY_SUBTITL_TWO}</p>
                    <p className='aboutCompnySubtitleCEO col-11'>{Strings.ABOUT_COMPANY_FOUNDER_TITLE}</p>
                </div>
                {/* <div className='abtDebit'>
                    <DebitCard />
                </div> */}
            </div>
        </div>
    );
}

export default aboutCompany;