import React from 'react';
import './style.css'
import Accordion from '../accordion';

const NavGosats = () => {
    const accordionData = [
        {
            title: 'What is GoSats and why do I need it?',
            content: `GoSats is a Cashback and Rewards application that enables stacking sats every time you shop. This way you aren't stuck with restrictive terms and conditions for redeeming your cashbacks also with no expiry dates or minimum purchase requirements.`
        },
        {
            title: 'What are Sats? ',
            content: `Sats is our in-app Reward Points mechanism redeemable for Electronic Gift Vouchers, Experiences, Merchandise and other loyalty programs.`
        },
        {
            title: 'What are Sats rewards and how are they earned?',
            content: (
                <>
                    We are replacing card points, miles and other conventional loyalty programs with Sats! We have 5 ways to earn free Sats on GoSats:
                    <br />
                    <ol>
                        <li>
                            Direct Shopping
                        </li>
                        <li>
                            Vouchers
                        </li>
                        <li>
                            GoSats Rewards Prepaid Card
                        </li>
                        <li>
                            Referral Rewards
                        </li>
                        <li>
                            Sats Spinner
                        </li>
                    </ol>
                </>
            )
        }

    ];
    return (
        <div className="accordion">
            {accordionData.map(({ title, content }, index) => (
                <Accordion title={title} content={content} key={index} />
            ))}
        </div>
    );
}

export default NavGosats;