import { useEffect } from 'react';

const PreventClickjacking = () => {
  useEffect(() => {
    const setXFrameOptions = () => {
      const meta = document.createElement('meta');
      meta.httpEquiv = 'X-Frame-Options';
      meta.content = 'DENY';
      document.head.appendChild(meta);
    };

    // Check if the current window is the top-level window
    if (window === window.top) {
      // If it is, set the X-Frame-Options header to DENY
      setXFrameOptions();
    } else {
      // If not, redirect the top-level window to the current URL
      window.top.location = window.self.location;
    }
  }, []);

  return null; // This component doesn't render anything, it just performs the check on mount
};

export default PreventClickjacking;
