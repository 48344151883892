import React from 'react';
import './style.css'
import Accordion from '../accordion';

const NavWithdrawals=()=>{
    const accordionData = [
        {
          title: 'How do I withdraw my sats? ',
          content: `All you need to do is head over to the wallet section of your app and tap the withdraw button. Once you fill in and confirm your withdrawal details the order will be processed in the next batch, it's that simple!  `
        },
        {
          title: 'Are there any fees for withdrawals? ',
          content: `A fee of 2000 sats will be deducted from the withdrawn amount. Rest assured, there are no hidden fees, charges, or gimmicks!`
        },
        {
          title: 'How long does it take for my sats to reach me? ',
          content: `All withdrawal requests are processed at 6 pm on Wednesdays. You will receive an email with your transaction details when it is completed.`
        },
        {
          title: 'I have not received my sats after withdrawal, what do I do?',
          content: `Exchanges sometimes need to manually process deposits, please contact them with the withdrawal information you received through e-mail so that they can resolve the issue internally.
          `
        }
      ];
    return(
        <div className="accordion">
          {accordionData.map(({ title, content}, index) => (
          <Accordion title={title} content={content} key={index}/>
           ))}
         </div>
    );
}

export default NavWithdrawals;