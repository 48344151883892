import React from 'react';
import './style.css';
import {Media} from '../../components'
import { FaArrowRight } from "react-icons/fa";
import { Strings, Images, Href } from '../../Constants';

const data = [
    {
        title: "Elevate Your Rewards Game",
        subTitle: "1.5% cashback rewards everytime you shop"
    },
    {
        title: "Beyond Traditional Earnings",
        subTitle: "Swipe anywhere and get rewards in valuable assets like Gold and Sats"
    }
]

const ShopAndEarn = () => {
    return (
        <div className='shopAndEarn'>
            {/* <Media /> */}
            <div className='sub-section'>
                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                    <div>
                        <div className='shopAndEarn-title'>Get India's Most <span className='shopAndEarnLinearOne'> Rewarding </span> Shopping Instrument</div>
                        <div className='shopAndEarn-subtitle'>Powered by <span className='shopAndEarnLinear'> Visa Platinum  </span></div>
                    </div>
                    <div className='col-md-12 col-lg-12 col-xl-12 imag-container ' >
                        <img src={Images.phones} alt='Shop and Earn' className='shopAndEarn-img' />
                    </div>
                    <div className='container landingSatsPayContainer'>
                        <div className='landingSatsPaySubContainer'>
                            <div className='landingSatsPayTitle'>
                                Introducing SatsPay!
                            </div>
                            <div className='landingSatsPaySubTitle'>
                                Scan & Pay with your earned rewards instantly!
                            </div>
                        </div>
                        <div>
                            <img className='landingScreenSatspayImg' src={Images.satsPayFAQImg}/>
                        </div>
                    </div>
                    <div className='container rewardCashbackTitleContainer'>
                        {
                            data.map((item, index) => (
                                <div key={index}>
                                    <div className='rewardCashbackTitleOne'>{item.title}</div>
                                    <div className='rewardCashbackSubTitleOne'>{item.subTitle}</div>
                                    {index === Math.floor(data.length / 3) && <div className='rewardCashbackDivider'></div>}
                                </div>
                            ))
                        }
                    </div>
                </div>
                {/* 
                    <div className='learn-more'>
                            <a className='learn-more-link' href={Href.ONRAMP_LINK} target="_blank" rel="noreferrer">Learn More
                                <FaArrowRight className="right-arrow" />
                            </a>
                    </div> 
                */}
            </div>
        </div>
    );
}

export default ShopAndEarn;