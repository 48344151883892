import React from 'react';
import './shopping.css'
import Accordion from '../accordion';

const NavShopping = () => {
  const accordionData = [
    {
      title: 'What is the “History” section in my app?',
      content: (
        <>
          It contains a list of all the orders and transactions made through the GoSats App. Two different tags will be shown under each transaction indicating the status of your order: <br />
          Tracked: Your order has been tracked and you will receive your sats per the date mentioned below your order.<br />
          Completed: Your order has been confirmed and the sats have been credited to your wallet.
        </>
      )
    },
    {
      title: 'What to do if my direct shopping order doesn’t appear in my History Section?',
      content: (
        <>
          Within 2 Days of the order:
          It normally takes us up to 2 days to get confirmation from our partners before the estimated sats show up in your history. So you should get the sat estimate as soon as the confirmation comes in.
          <br />
          After Day 3 of the order:
          Unfortunately, this could mean that your order has not been tracked. We will need to raise an untracked order request with the brand. Our in-app support team will help you with this process.
        </>
      )
    },
    {
      title: 'Why does my direct shopping order not get tracked?',
      content: (
        <>
          The following are some key issues that can lead to an untracked order:
          <br />
          -Adding items to the cart before going through GoSats
          <br />
          -Adding items across multiple sessions rather than doing so at once
          <br />
          Using privacy browsers like Brave
          -Do check out our instructional video on direct shopping if your orders do not get tracked:
          <a href="https://youtu.be/3DnDudo78vo"> https://youtu.be/3DnDudo78vo</a>
        </>
      )
    },
    {
      title: 'How long does it take for sats earned through direct shopping orders to get confirmed?',
      content: (
        <>
          Sats earned from direct shopping get confirmed once we receive the cashback from the brand's. This takes anywhere between 60-90 days. You can view the estimated date of confirmation in the history section.
        </>
      )
    }
  ];
  return (
    <div className="accordion">
      {accordionData.map(({ title, content }, index) => (
        <Accordion title={title} content={content} key={index} />
      ))}
    </div>
  );
}

export default NavShopping;