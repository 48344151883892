import { FaFacebookF, FaLinkedinIn, FaTwitter, FaInstagram, FaTelegramPlane, FaInstagramSquare } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import { BsWhatsapp } from 'react-icons/bs';

const Icons ={
    FaFacebookF,
    FaLinkedinIn,
    FaTwitter,
    FaInstagram,
    FaTelegramPlane,
    AiFillInstagram,
    MdEmail,
    BsWhatsapp
}

export default Icons;