import React from "react";
import './style.css';
import { Strings } from "../../Constants";

const Customers=()=>{
    
    return(
        <div className='container customers'>
            <div className='col-12'>
                <div className='customers-sub-title'>
                    <div className='customers-sub-title'>{Strings.TESTIMONIALS_TITLE}</div>
                </div>  
            </div>
        </div>
    );
}

export default Customers;