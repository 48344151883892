import React from 'react';
import { NavLink } from 'react-router-dom';
import './vision.css';
import { FaArrowRight } from "react-icons/fa";
import { Images, Strings } from '../../Constants';
const Vision = () => {
    return (
        <div className='container  vision-container'>
           {/*  <TeamVision /> */}
            <Mission />
        </div>
    );
}

const TeamVision = () => {
    return (
        <div className='visionSection d-flex flex-wrap'>
            <div className='col-12 col-md-6 col-lg-6 col-xl-6 visionSubTextHolder'>
                <h1 className='visionTitle col-11'>{Strings.VISION_TITLE}</h1>
            </div>
            <div className='visionSubTitle col-12 col-md-6 col-lg-6 col-xl-6'>
                <p className='text-justify visionSub'>{Strings.VISION_SUB_TITLE_ONE}</p>
                <p className='text-justify visionSub'>{Strings.VISION_SUB_TITLE_TWO}</p>
                {/* <div className='learn-more'>
                    <NavLink to='/aboutGosats' className='learn-more-link'>Learn More
                        <FaArrowRight className="right-arrow" />
                    </NavLink>
                </div> */}
            </div>
        </div>
    )
}

const Mission = () => {
    return (
        <div className='imgSection'>
            <div className='vision-team-container'>
                <div className='image2 '>
                    <img src={"https://assets.gosats.io/notification/2024/4/1714461018910"} className='visionImg2 ' alt='Gosats Team' />
                </div>
                <div className='imageMobile'>
                    <img src={"https://assets.gosats.io/notification/2024/4/1714464770852"} className='visionImgMob ' alt='Gosats Team' />
                </div>
            </div>
           {/*  <div className='d-flex flex-wrap justify-content-between  vision-container2'>
                <div className='ourVision  col-12 col-md-6 col-lg-5 col-xl-5'>
                    <img src={Images.Hblue} className='hblue' alt='Icon' />
                    <h1 className='ourVisionTitle '>{Strings.OUR_VISION_TITLE}</h1>
                    <p className='ourVisionSubtitle col-11'>{Strings.OUR_VISION_SUB_TITLE}</p>
                </div>
                <div className='ourMission  col-12 col-md-6 col-lg-5 col-xl-5'>
                    <img src={Images.Hred} className='hred' alt='Icon' />
                    <h1 className='ourMisionTitle '>{Strings.OUR_MISSION_TITLE}</h1>
                    <p className='ourMisionSubtitle col-11'>{Strings.OUR_MISSION_SUB_TITLE}</p>
                </div>
            </div> */}
        </div>
    )
}

export default Vision;