import React, { useState } from 'react';
import './accordion.css';
import { GoPlus } from "react-icons/go";
import { FaMinus } from "react-icons/fa";

const Accordion = ({ title, content }) => { 
  const [isActive, setIsActive] = useState(false);

 /*  const displayArrayText = (content) => {
    return (
      <p>${content}</p>
    )
  } */
  return (
    <div className="accordion">
      <div className="accordion-title d-flex justify-content-between  align-items-center" onClick={() => setIsActive(!isActive)}>
        <div className="title">{title}</div>
        <div className=" justify-content-end">
        <span >{isActive ?
          <span className='toggleBtnClose'> <FaMinus color='#A6A7A8'/> </span> 
          : 
          <span className='toggleBtnOpen'> <GoPlus color='#2A2E31'/> </span>}</span></div>
      </div>
      {isActive && <div className="accordion-content subText col-xl-8"><p>{content}</p></div>}
      {/* {isActive && <div className="accordion-content subText">{displayArrayText(content)}</div>} */}
    </div>
  );
};

export default Accordion;