import React from 'react';
import './style.css';
import { Strings } from '../../Constants';
import { Config } from '../../Configs';
import {FaqNavbar, DebitCard} from '../../components';


const FAQ = () => {
    return (
        <div className='faq-container'>

            <div className='container'>
                {/* <div className='debSection'>
                    <DebitCard />
                </div> */}
                <div className=' faq-subsection '>
                    <div className='col-12 faq-container '>
                        <div className=' faq-title'>
                            <h2 className='faq-title'>{Strings.FAQ_TITLE}</h2>
                        </div>
                        <div className='col-11 faq-subtitle col-md-12'>
                            <p>{Strings.FAQ_SUBTITLE} Please write to us at  <a href={Config.MAIL_TO} className='goto-mail'>support@gosats.io</a></p>
                        </div>
                    </div>
                    <div className='col-12  faq-navbar'>
                        <FaqNavbar />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAQ;