import React from 'react';
import './values.css';

const Values = (props) => {
    var { valuesImg, valuesTitle, valuesSubtitle, bgColor, textColor, shadow, mrgTop, titleWidth, padLeft, subTextColor } = props.valuesDetails
    return (
        <div className='perseverance col-12 col-md-12 col-lg-3 col-xl-3 ' style={{ backgroundColor: bgColor, boxShadow: shadow, marginTop: mrgTop, paddingLeft: padLeft }}>
            {
                valuesImg === undefined ?
                    null
                    :
                    <img src={valuesImg} className='perseveranceHblue' alt='Gosats' />
            }
            <h1 className='perseveranceTitle ' style={{ color: textColor, width: titleWidth }}>{valuesTitle}</h1>
            <p className='perseveranceSubTitle col-12' style={{ color: subTextColor }}>{valuesSubtitle}</p>
        </div>
    );
}

export default Values;



