import React, { useState } from 'react';
import './alredyReg.css';
import { Modal } from "react-bootstrap";
import SpinPopUp from '../../images/spinPopUpAlrdyReg.png';

const AlredyReg = () => {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);

    return (
        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className='alrdeyRegSpinRewardPopUp'>
            <Modal.Header className="alrdyRegpopUpTop">
            </Modal.Header>
            <Modal.Body className="alrdyRegpopUpSImg">
                <img src={SpinPopUp} className='alrdyPopImg' alt='Icon' />
            </Modal.Body>
            <h1 className='alrdyPopUptitle'>You’re already registered with us.</h1>
            <p className='alrdyPopUpsubTitle '>Use Gosats App to earn daily bitcoin rewards with Sats Spin</p>
        </Modal>
    );
}

export default AlredyReg;