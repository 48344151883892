import React from 'react';
import './style.css';
import { TabPanel, useTabs } from 'react-headless-tabs';
import NavCard from './pages/Card';
import NavShopping from './pages/ShoppingComponent/shopping';
import NavVoucher from './pages/VouchersComponent';
import NavWithdrawals from './pages/WithdrawalComponent';
import NavRefferal from './pages/RefferalComponent';
import NavGosats from './pages/GoSata';

const FaqNavbar = () => {

    const [selectedTab, setSelectedTab] = useTabs([
        "gosats",
        "shopping",
        "card",
        "referral",
        "vouchers",
        "withdrawals"
    ]);

    return (
        <div>
            <div className='navLinkContainer'>
                <nav className='d-flex  faq-link justify-content-between col-xl-8'>
                    <div className='linear'></div>
                    <TabSelector
                        isActive={selectedTab === "gosats"}
                        onClick={() => setSelectedTab("gosats")}
                    >
                        GoSats
                    </TabSelector>
                    <TabSelector
                        isActive={selectedTab === "shopping"}
                        onClick={() => setSelectedTab("shopping")}
                    >
                        Shopping
                    </TabSelector>
                    <TabSelector
                        isActive={selectedTab === "card"}
                        onClick={() => setSelectedTab("card")}
                    >
                        Card
                    </TabSelector>
                    <TabSelector
                        isActive={selectedTab === "referral"}
                        onClick={() => setSelectedTab("referral")}
                    >
                        Referral
                    </TabSelector>
                    <TabSelector
                        isActive={selectedTab === "vouchers"}
                        onClick={() => setSelectedTab("vouchers")}
                    >
                        Vouchers
                    </TabSelector>
                    <TabSelector
                        isActive={selectedTab === "withdrawals"}
                        onClick={() => setSelectedTab("withdrawals")}
                    >
                        Withdrawals
                    </TabSelector>

                </nav>
                <div className='navDivider col-xl-8'></div>
            </div>
            <div className="p-4">
                <TabPanel hidden={selectedTab !== "gosats"}><NavGosats /></TabPanel>
                <TabPanel hidden={selectedTab !== "shopping"}><NavShopping /></TabPanel>
                <TabPanel hidden={selectedTab !== "card"}><NavCard /></TabPanel>
                <TabPanel hidden={selectedTab !== "referral"}><NavRefferal /></TabPanel>
                <TabPanel hidden={selectedTab !== "vouchers"}><NavVoucher /></TabPanel>
                <TabPanel hidden={selectedTab !== "withdrawals"}><NavWithdrawals /></TabPanel>
            </div>
        </div>
    );
}



const TabSelector = ({isActive, children, onClick }) => (
    <button
    className={`navLink ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
        {children}
    </button>

);

export default FaqNavbar;