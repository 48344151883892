import React from 'react';
import { BrowserRouter as BRouter, Routes, Route } from "react-router-dom";
import './App.css';
import { HomePage, Footer, About, CardScreen, AboutGosats, YearRecap2022, DeleteAccount, RedirectComponent, Headder, ScanAndPay } from './components';

const App = () => {

  return (
    <div className='App'>
      <BRouter>
        <Headder />
        <Routes>
          <Route path='*' element={<HomePage />} />
          <Route path='/about' element={<About />} />
          <Route path='/team' element={<About />} />
          <Route path='/card' element={<CardScreen />} />
          <Route path='/faq' element={<HomePage />} />
          <Route path='/aboutGosats' element={<AboutGosats />} />
          <Route path='/satsPay' element={<ScanAndPay />} />
          <Route path='/2022' element={<YearRecap2022 />} />
          <Route path='/deleteAccount' element={<DeleteAccount />} />
          <Route path="/support/*" element={<RedirectComponent />} />
        </Routes>
        <Footer />
      </BRouter>
    </div>
  );
}

export default App;
