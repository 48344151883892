import './style.css';
import { Strings } from '../../Constants';
import { Images } from '../../Constants';
import ReactLottie from 'react-lottie';
import animationData from '../../LottieFiles/SatsPay Web_Anim.json';

const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

const ScanAndPay = () => {
    return (
        <>
            <ScanAndPayLandingScreen />
            <ScanAndPayFeatureSection />
            <ScanAndPayFAQ />
        </>
    )
}

const ScanAndPayLandingScreen = () => {
    return (
        <div className='container satsPayContainer'>
            <div className='satsPayTitleContainer'>
                <div className='satsPayTitle'>
                    {Strings.SATSPAY_TITLE}
                </div>
                <div className='satsPaySubTitle'>
                    {Strings.SATSPAY_SUBTITLE}
                </div>
            </div>
            <div className='satsPayImg'>
                {/* <img className='satsPayImg' src={Images.satsPay} /> */}
                <ReactLottie
                    options={defaultOptions}
                    height={'100%'}
                    width={'100%'} />
            </div>
        </div>
    )
}

const ScanAndPayFeatureSection = () => {
    return (
        <div className='container ScanAndPayFutrSectContainer'>
            <div className='satsPayFutrSectTitle'>
                {Strings.SATSPAY_FTR_TITLE}
            </div>
            <div className='satsPayFutrSectSubTitleContainer'>
                <div className='satsPayFutrSectSubSec'>
                    <img className='satsPaySubImg' src={Images.satsPaySubImgOne} />
                    <div className='satsPayFutrSectSubTitle'>Experience the convenience of <span style={{ color: '#FBCC65' }}> secure & rewarding </span> payments with SatsPay.</div>
                </div>
                <div className='satsPayFutrSectSubSec'>
                    <img className='satsPaySubImg' src={Images.satsPaySubImgTwo} />
                    <div className='satsPayFutrSectSubTitle'><span style={{ color: '#FBCC65' }}>Redeem </span> using the GoSats card balance.</div>
                </div>
            </div>
        </div>
    )
}

const ScanAndPayFAQ = () => {
    return (
        <div className='container ScanAndPayFAQSectContainer'>
            <div className='ScanAndPayFAQTitle'>
                <div className='satsPayFAQSectTitle'>{Strings.SATSPAY_FAQ_TITLE}</div>
                <div className='satsPayFAQSectSubTitle'>{Strings.SATSPAY_FAQ_SUBTITLE_ONE}</div>
                <div className='satsPayFAQSectSubTitle'>{Strings.SATSPAY_FAQ_SUBTITLE_TWO}</div>

            </div>
            <div className='satsPayFAQImgContainer'>
                <img className='satsPayFAQImg' src={Images.satsPayFAQImg} />
            </div>
        </div>
    )
}


export default ScanAndPay;